.specialButtons {
  display: block;
  position: fixed;
  bottom: 5.2em;
  overflow-x: visible;
  right: -7.57rem;
  text-align: right;
  z-index: 100;
  width: 2.4rem;
  width: 10.4rem;
}
.specialButtons a {
  display: block;
  position: relative;
  background: rgb(67, 38, 128,1);
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 1rem;
  padding-left: 3rem;
  padding-right: 15rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
  // font-family: "Aeonis LT W01 Bold1328720",Arial;
  font-weight: normal;
  transition: all 0.5s;
  white-space: nowrap;
}
.specialButtons a:hover{
  color: #fff;
  cursor: pointer;
}
//.specialButtons a:hover,
.specialButtons a.hovered {
  color: #fff;
  background: rgb(67, 38, 128,1);
  background: rgb(154, 205, 50,1) !important;
  color: rgb(67, 38, 128,1);
  -ms-transform: translate(-190px, 0);
  -webkit-transform: translate(-190px, 0);
  transform: translate(-190px, 0);
  cursor: pointer;
}
// to handle mobile transition
@media only screen and (max-width: 600px) {

.specialButtons a:hover a:focus{
  color: #fff;
  background: rgba(122,105,87,1);
  -ms-transform: translate(-190px,0);
  -webkit-transform: translate(-190px,0);
  transform: translate(-190px,0);
}
}


.specialButtons a i {
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: auto;
  color: #fff;
  background: transparent;
  border: none;
  font-size: 1.5em;
  // padding: 0.5rem 0.5rem 0.3rem 0.6rem;
  // padding-bottom: 0.59em;
  margin: 0;
  border-image-width: 0 !important;
}


.slideUp {
  display: inline-block !important;
  width: auto;
  -ms-transform: translate(295px,0) !important;
  -webkit-transform: translate(295px,0) !important;
  transform: translate(295px,0) !important;
  font-size: 1.35rem !important;
  padding-bottom: 1.8rem;
  display: inline-block !important;
  width: auto;
  background: rgb(154, 205, 50,1) !important;
  color: rgb(67, 38, 128,1);
  transform: translate(295px, 0) !important;
  font-size: 1.35rem !important;
  height: 45px;
  padding-top: 0.5rem !important;
}
.specialButtons > .slideUp > a
{
  // background: red;
}
.slideUp a:hover {
  color: #fff;
  background: rgba(122,105,87,1);
  color: rgb(67, 38, 128,1);
  -ms-transform: translate(-390px,0);
  -webkit-transform: translate(-390px,0);
  transform: translate(-390px,0);
}

.showAnker {
  -ms-transform: translate(0,0) !important;
  -webkit-transform: translate(0,0) !important;
  transform: translate(0,0) !important;
}

.theIconItself{
  position: absolute;
  // left: 7px;
  // top: 12px;
  left: 4%;
    top: 27%;
  height: 2em; 
  width:1.5em;
  height:1.5em;
}
.theGoTopIcon{
  position: absolute;
  left: 13px;
  height: 25px;
  z-index: 100000;
  color: rgb(67, 38, 128,1);
}